














import { Component } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class Page404 extends Vue {
  mounted() {
    document.title = (this.$route.meta as any).title || "GO2TR BMS";
  }
}
